/* eslint-disable */
import api from '@/services/api';
import apiNoAuth from '@/services/apiNoAuth';
import AppError from '@/utils/appError';

const getCorretorasPorCpf = ({ cpf }) => api.post('/catalogo/corretoras', { cpf });

const getEndereco = async ({ cep }) => {
  try {
    const config = { headers: { "X-Gravitee-Api-Key": process.env.VUE_APP_ENDERECO_API_KEY } };
    const { data } = await apiNoAuth.get(`enderecos/cep/${cep}`, config);
    return data.data;
  } catch (error) {
    const { response } = error;
    throw new AppError(response, 400);
  }
};

const getProfissoes = async ({ uf, cidade, administradora }) => {
  try {
    const config = { headers: { "X-Gravitee-Api-Key": process.env.VUE_APP_PUBLICO_ALVO_API_KEY } };
    const { data } = await api.get(`publicos-alvo?uf=${uf}&cidade=${cidade}&administradora=${administradora}`, config);
    return data.data;
  } catch (error) {
    const { response } = error;
    throw new AppError(response, 400);
  }
};

const getEntidades = async ({ uf, cidade, publicoAlvo, administradora, corretora, }) => {
  try {
    let paramUF = '';
    let paramCidade = '';
    let paramPublicoAlvo = '';
    let paramAdministradora = '';
    let paramCorretora = '';

    if (uf)
      paramUF = `?uf=${uf}`;

    if (cidade)
      paramCidade = (paramUF ? '&' : '?').concat(`cidade=${cidade}`);

    if (publicoAlvo)
      paramPublicoAlvo = (paramUF || paramCidade ? '&' : '?').concat(`publicoAlvo=${publicoAlvo}`);

    if (administradora)
      paramAdministradora = (paramUF || paramCidade || paramPublicoAlvo ? '&' : '?').concat(`administradora=${administradora}`);

    if (corretora)
      paramCorretora = (paramUF || paramCidade || paramPublicoAlvo  || paramAdministradora ? '&' : '?').concat(`corretora=${corretora}`);

    const config = { headers: { "X-Gravitee-Api-Key": process.env.VUE_APP_ENTIDADE_API_KEY } };
    const { data } = await api.get(`entidades${paramUF}${paramCidade}${paramPublicoAlvo}${paramAdministradora}${paramCorretora}`, config);
    return data.data;

  } catch (error) {
    const { response } = error;
    throw new AppError(response, 400);
  }
};
const getOperadoraPorProposta = async ({
  publicoAlvo, entidade, uf, cidade, cpfCnpj, administradora,
}) => {
  try {
    const config = {
      params: {
        publicoAlvo,
        uf,
        cidade,
        corretora: cpfCnpj || undefined,
        administradora: administradora ? (Array.isArray(administradora) ? administradora.join() : administradora) : undefined,
      },
      headers: { "X-Gravitee-Api-Key": process.env.VUE_APP_OPERADORA_API_KEY }
    };
    const { data } = await api.get(`operadoras/${entidade}`, config);
    return data.data;
  } catch (error) {
    const { response } = error;
    throw new AppError(response, 400);
  }
};

const getOperadoras = async () => {
  try {
    const config = { headers: { "X-Gravitee-Api-Key": process.env.VUE_APP_OPERADORA_API_KEY } };
    const { data } = await api.get(`operadoras`, config);
    return data.data;
  } catch (error) {
    const { response } = error;
    throw new AppError(response, 400);
  }
};

const getOperadorasPorEstado = async ({ estado }) => {
  try {
    const config = { headers: { "X-Gravitee-Api-Key": process.env.VUE_APP_OPERADORA_API_KEY } };
    const { data } = await api.get(`operadoras/uf/${estado}`, config);
    return data.data;
  } catch (error) {
    const { response } = error;
    throw new AppError(response, 400);
  }
};

const getTipoSexo = async () => {
  try {
    const config = { headers: { "X-Gravitee-Api-Key": process.env.VUE_APP_TIPO_SEXO_API_KEY } };
    const { data } = await api.get(`tipos-sexo`, config);
    return data.data;
  } catch (error) {
    const { response } = error;
    throw new AppError(response, 400);
  }
};

const getEstadoCivil = async () => {
  try {
    const config = { headers: { "X-Gravitee-Api-Key": process.env.VUE_APP_ESTADO_CIVIL_API_KEY } };
    const { data } = await api.get(`estado-civil`, config);
    return data.data;
  } catch (error) {
    const { response } = error;
    throw new AppError(response, 400);
  }
};

const getEntidadesPorProfissao = ({ publicoAlvo }) => api.post('/catalogo/entidades', { publicoAlvo });

const getGrauParentesco = ({ idProdutoFatura, estadoCivil, dataVigencia, publicoAlvoTitular, dataNascimentoTitular }) => {
  let filtroVigencia = ``;
  if (dataVigencia) {
    filtroVigencia = `?dataVigencia=${dataVigencia}`;
  }

  let filtroPublicoAlvo = ``;
  if (publicoAlvoTitular) {
    filtroPublicoAlvo = `${filtroVigencia ? '&' : '?'}publicoAlvoTitular=${publicoAlvoTitular}`;
  }

  let filtroDataNascimentoTitular = ``;
  if (dataNascimentoTitular) {
    filtroDataNascimentoTitular = `${filtroVigencia || filtroPublicoAlvo ? '&' : '?'}dataNascimentoTitular=${dataNascimentoTitular}`;
  }

  const config = { headers: { "X-Gravitee-Api-Key": process.env.VUE_APP_PARENTESCO_API_KEY } };
  return api.get(`/parentescos/dependentes/${idProdutoFatura}/${estadoCivil}${filtroVigencia}${filtroPublicoAlvo}${filtroDataNascimentoTitular}`, config)
};

const getGrauParentescoResponsavel = ({ idProdutoFatura, dataVigencia, publicoAlvoTitular }) => {
  let filtroVigencia = ``;
  if (dataVigencia) {
    filtroVigencia = `?dataVigencia=${dataVigencia}`;
  }

  let filtroPublicoAlvo = ``;
  if (publicoAlvoTitular) {
    filtroPublicoAlvo = `${filtroVigencia ? '&' : '?'}publicoAlvoTitular=${publicoAlvoTitular}`;
  }

  const config = { headers: { "X-Gravitee-Api-Key": process.env.VUE_APP_PARENTESCO_API_KEY } };
  return api.get(`/parentescos/responsavel/${idProdutoFatura}${filtroVigencia}${filtroPublicoAlvo}`, config)
};

const getEstados = async () => {
  try {
    const config = { headers: { "X-Gravitee-Api-Key": process.env.VUE_APP_ENDERECO_API_KEY } };
    const { data } = await api.get(`enderecos/estados`, config);
    return data.data;
  } catch (error) {
    const { response } = error;
    throw new AppError(response, 400);
  }
};

const getCidadePorEstado = async ({ estado }) => {
  try {
    const config = { headers: { "X-Gravitee-Api-Key": process.env.VUE_APP_ENDERECO_API_KEY } };
    const { data } = await api.get(`enderecos/estados`, config);
    return data.data;
  } catch (error) {
    const { response } = error;
    throw new AppError(response, 400);
  }
};

const verificaEntidadeTemCnae = async ({ uf, cidade, administradora, publicoAlvo, operadora, entidade }) => {
  try {
    const config = { headers: { "X-Gravitee-Api-Key": process.env.VUE_APP_ENTIDADE_API_KEY } };
    const { data } = await api.post(`entidades/cnae`, { uf, cidade, administradora, publicoAlvo, operadora, entidade }, config);
    return data.data;
  } catch (error) {
    const { response } = error;
    throw new AppError(response, 400);
  }
};

export default {
  getCorretorasPorCpf,
  getEndereco,
  getEstados,
  getProfissoes,
  getEntidades,
  getEntidadesPorProfissao,
  getOperadoraPorProposta,
  getOperadoras,
  getCidadePorEstado,
  getOperadorasPorEstado,
  getGrauParentesco,
  getGrauParentescoResponsavel,
  getTipoSexo,
  getEstadoCivil,
  verificaEntidadeTemCnae,
};
